import React from 'react';
import { useEffect, useState } from 'react';
import Cards from '../../pos/extra/Cards';

const Header = () => {
    const position = sessionStorage.getItem('position');

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
                setWidth(window.innerWidth);
        }
    
        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);
    
        return (width <= 768);
    }

    const Logout = () => {
        sessionStorage.removeItem('username')
        localStorage.setItem('LabRequestCheckBox', JSON.stringify({}))
        window.location.assign('/')
    }

    return (
        <nav className={position != 'cashier' ? "main-header navbar navbar-expand navbar-white navbar-light" : "navbar navbar-expand navbar-white navbar-light"}>
            {/* Left navbar links */}
            <ul className="navbar-nav">
                {
                    position != 'cashier' ? 
                    <li className="nav-item" style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", gap: 0, alignItems:"center"}}>
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /> 
                            {/* <span>Expand</span>  */}
                        </a> 
                    </li>
                    :null
                }
                {/* <li className="nav-item d-none d-sm-inline-block">
                    <a href="index3.html" className="nav-link">Home</a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <a href="#" className="nav-link">Contact</a>
                </li> */}
            </ul>
            {/* Right navbar links */}
            {/* <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                    <a onClick={()=>Logout()} className="nav-link" data-toggle="dropdown" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="red" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg>

                    </a>
                </li>
            </ul> */}
        </nav>

    );
}

export default Header;
