import React, { useEffect } from 'react';
import ENV from '../ENV';
import io from "socket.io-client";

import './css/PatientRow.css';

const PatientRow = (props) => {
    // const socket = io.connect(ENV.socketAPI);

    // useEffect(async()=>{    
    //     await socket.emit("join_room", {room: '4M09'})
    //     socket.on("receive_data", (data) => {
    //         console.log(data)
    //     })
    // }, [socket])
    const clicking = () => {
        if (props.device === "mobile") {
            props.setSearch('')
            props.onClickedPatient(props.item)
            // alert(props.item.fullname);
            return;
        }
        props.onClickedPatient(props.item)    
    }
    const _clicking = () => {
        props.onClickedPatient(props.item)
        props.onClickedButton('PatientInfoPanel')   
    }
    return (
        <div onClick={() => {
            props.onclickedpatient?.patient_id === props.item.patient_id ? 
            _clicking()
            :
            clicking()
        }} style={{ display: "flex", cursor: props.onclickedpatient?.patient_id === props.item.patient_id ? null : "pointer", flexDirection: "row", width: "100%", justifyContent: "space-between", borderBottom: "1px solid #ddd", backgroundColor: props.onclickedpatient?.patient_id === props.item.patient_id ? "#43bea0" : "#fff" }}>
            {/* <div style={{ padding: 5 }}>
                <div style={{ background: "#ddd", width: 50, height: 50, borderRadius: "100%" }}></div>
            </div> */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flexGrow: 1, maxHeight: 100, backgroundColor: props.onclickedpatient?.patient_id === props.item.patient_id ? "#43bea0" : null, color: props.onclickedpatient?.patient_id === props.item.patient_id ? "#fff" : null }}>
                <div style={{ paddingLeft: 5 }}>
                    <div style={{ fontWeight: 600, fontSize: window.innerWidth === 1024 ? 12 : window.innerWidth === 768 ? 12 : null }}>{props.item.fullname}</div>
                    <div style={{ fontSize: 12, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                        <span>{props.item.gender === 'male' ? 'Male' : 'Female'}</span>
                        <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                        <span>{props.item.age} years old</span>
                    </div>
                </div>
                {
                    props.item.seen === "1" ?
                        (
                            <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <span style={{ fontSize: 15, fontWeight: 600, color: "#6161e1" }}>Follow Up</span>
                                <span style={{ fontSize: 12, fontWeight: 600, color: "#6161e1" }}>Checkup</span>
                            </div>
                        ) : null
                }
            </div>
        </div>
    )
}

export default PatientRow;
