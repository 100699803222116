import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../ENV';
import moment from 'moment';
import ChartingImageIcon from '../extra/ChartingImageIcon';

const ChartingInfront = ({ onclickedpatient }) => {

    const refHeight = useRef(null)

    const [chartingList, setChartingList] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("charting_list_infront", 1); //onclickedpatient.patient_id
        formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setChartingList(res.data)
        })
    }, [ENV, onclickedpatient])
    // alert(refHeight.current.clientHeight)
    // useEffect(()=>{
    //     console.log(refHeight.current.clientHeight)
    // }, [])
    const num = 0;
    if(chartingList.length>0) {
        return (
            <div>
                <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                    <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontSize: 18, fontWeight: 600 }}>Charting</div>
                            <div style={{ fontSize: 18, fontWeight: 500 }}>
                                {/* <button onClick={() => setMedicalRecordButton('Consultation_history')} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff" }}>
                                    <span>View History</span>
                                    <span className='pi pi-arrow-right' />
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: 10 }}>
                        {/* <div style={{ paddingBottom: 5, fontWeight: "bolder" }}>Issued Medical Documents</div> */}
                        <div className='table-responsive' style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", border: "1px solid #ddd" }}>
                            
                                <table className='table table-bordered' style={{marginBottom: 0}}>
                                    <tbody>
                                    {
                                        chartingList?.map((item, key) => {
                                            if (Number(chartingList.length - 1) == key || Number(chartingList.length - 2) == key) {
                                                return item.list.map((i, k) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ fontSize: 12, width: 50, border: k !== 0 ? 0 : k === 0 ? 0 : null, borderBottom: k === 0 ? 0 : k !== 0 ? 0 : null, borderTop: k !== 0 ? 0 : k === 0 ? null : null, padding: 2}}>
                                                                { k === 0 ? item.date : null }
                                                            </td>
                                                            <td style={{ fontSize: 12, width: 50, border: k !== 0 ? 0 : k === 0 ? 0 : null, borderBottom: k === 0 ? 0 : k !== 0 ? 0 : null, borderTop: k !== 0 ? 0 : k === 0 ? null : null, padding: 2}}>
                                                                {k === 0 ? moment(i.date_time).format('hh:mm:ss A') : null}
                                                            </td>
                                                            <td style={{width: 200, fontSize: 12, padding: 2, borderBottom: key === 0 ? 0 : key !== 0 ? 0 : null}}>
                                                                {
                                                                    i.images != '' ?
                                                                        <div style={{ padding: 5 }}>
                                                                            <ChartingImageIcon onclickedpatient={onclickedpatient} items={i} />
                                                                            {/* <Image downloadable={true} downloadIcon={false} src={ENV.DOMAIN + 'charting/' + items.images} indicatorIcon={icon} alt="Image" preview width="100" /> */}
                                                                            {/* <img src={ENV.DOMAIN + 'charting/'+items.images} style={{width: 100, height: 100}} /> */}
                                                                        </div>
                                                                        :
                                                                        i.focus
                                                                }
                                                            </td>
                                                            <td style={{width: 200, fontSize: 12, padding: 2, borderBottom: key === 0 ? 0 : key !== 0 ? 0 : null}}>
                                                                {i.progress}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        })
                                    }
                                    </tbody>
                                </table>
                            
                            {/* {
                                chartingList?.map((item, key) => {
                                    if (Number(chartingList.length-1) == key || Number(chartingList.length-2) == key) {

                                        return (
                                            <div key={key} style={{ flex: 1, display: "flex", flexDirection: "row", width: "100%"}}>
                                                <div style={{ flex: 2, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                    {
                                                        item.list.map((i, k) => {
                                                            return (
                                                                <div key={k} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

                                                                    {
                                                                        Number(chartingList.length - 1) >= key ?
                                                                            <div style={{ flex: 1, padding: 5, fontSize: 12, borderTop: k === 0 ? "1px solid #ddd" : null }}>
                                                                                {
                                                                                    k === 0 ? item.date : null
                                                                                }
                                                                            </div>

                                                                            : null
                                                                    }

                                                                    <div style={{
                                                                        padding: 5,
                                                                        borderTop: k == 0 ? "1px solid #ddd" : null,
                                                                        borderLeft: k == 0 ? "1px solid #ddd" : "1px solid #ddd",
                                                                        borderRight: k == 0 ? "1px solid #ddd" : "1px solid #ddd",
                                                                        // borderBottom: k == item.list.length - 1 ? "1px solid #ddd" : null,
                                                                        width: 100,
                                                                        fontSize: 12
                                                                    }}>
                                                                        {k == 0 ? moment(i.date_time).format('hh:mm:ss A') : null}
                                                                    </div>
                                                                    <div style={{ flex: 1, padding: 5, border: "1px solid #ddd", width: 200, fontSize: 12 }}>
                                                                        {
                                                                            i.images != '' ?
                                                                                <div style={{ padding: 5 }}>
                                                                                    <ChartingImageIcon onclickedpatient={onclickedpatient} items={i} />
                                                                                </div>
                                                                                :
                                                                                i.focus
                                                                        }
                                                                    </div>
                                                                    <div style={{ flex: 3, padding: 5, border: "1px solid #ddd", fontSize: 12 }}>
                                                                        {i.progress}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            } */}
                        </div>
                    </div>

                </div>
            </div>
        );
    } else {
        return (<></>)
    }
}

export default ChartingInfront;
