import React, { useContext, useState } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import { useEffect } from 'react';
import AppContext from './context/AppContext';
import io from "socket.io-client";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useGeolocated } from "react-geolocated";

const Login = () => {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

        // console.log(coords)

    // const socket = io.connect("http://192.168.1.11:3001");

    const {state, dispatch} = useContext(AppContext);

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
                setWidth(window.innerWidth);
        }
    
        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);
    
        return (width <= 768);
    }
    useEffect(async()=>{    
        // await socket.emit("join_room", {room: 123})
        // await socket.emit("send_data", {room: 123, message: 'asdassssdasd'})
        // socket.on("receive_data", (data) => {
        //     console.log(data.data)
        // })

        // console.log(socket)
    }, [])

    // console.log()
    
    const history = useHistory()
    // const { refid } = useParams();

    // console.log(refid)

        // document.body.className = 'hold-transition login-page';
    // if (!useCheckMobileScreen()) {
    //     document.body.style.backgroundImage = 'url(https://assets.nst.com.my/images/articles/060616LET02pix1.jpg.transformed.jpg)';
    //     document.body.style.backgroundRepeat = 'no-repeat';
    //     document.body.style.backgroundSize = 'cover';
    //     document.body.style.backgroundPosition = 'center center';
    // } else {
    //     document.body.style.backgroundImage = 'url(https://www.verdict.co.uk/wp-content/uploads/2019/08/water-harvester.jpg)';
    //     document.body.style.backgroundRepeat = 'repear-y';
    //     document.body.style.backgroundSize = 'cover';
    //     document.body.style.backgroundPosition = 'center';
    // }


    const [inputvalue, setInputValue] = useState({})
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    // alert("")

    const LoginBtn = async (event) => {
        event.preventDefault();

        var formData = new FormData();
        formData.append("login", 1);
        formData.append("username", inputvalue?.username);
        formData.append("password", inputvalue?.password);
        formData.append("Geolocated", JSON.stringify(coords));

        if(inputvalue?.username=='' && inputvalue?.password==''){
            swal({
                title: "Invalid!",
                text: "Please Enter username and password",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            console.log(res.data)
            if (res.data[0].status == 'success') {
                swal({
                    // title: "",
                    text: "Successfully Loggedin",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(()=>{
                    console.log(res.data)
                    sessionStorage.setItem('user_id', res.data[0].user_id)
                    sessionStorage.setItem('username', res.data[0].username)
                    sessionStorage.setItem('position', res.data[0].position)
                    if (res.data[0].position == 'cashier') {
                        window.location.assign("/")
                    } else {
                        window.location.assign("/patient")
                    }
                });
            } else {
                swal({
                    // title: "Invalid!",
                    text: "Incorrect username and password",
                    icon: "error",
                    timer: 2000,
                    button: false,
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    // console.log(state.user_id, Number(sessionStorage.getItem('user_id')))

    const Display = () => {
        if (window.innerWidth === 360) {
            return (
                <>
                    <div className="login-logo">
                        {/* <img src={require('./384540628_1008238667298426_6685715244998088129_n.jpg')} style={{width:200, height:200, borderRadius:100}} /> */}
                        {/* <div style={{ lineHeight: 1, fontSize: 25 }}><a>Aguilar Medical Clinic <br /> & <br /> Pharmacy</a></div> */}
                        <div><a>John Doe, MD</a></div>
                        {/* <div><a style={{ fontSize: 15 }}>MD, FPPS, GENERAL PEDIATRICS</a></div> */}
                    </div>
                        <div className="card-body login-card-body">
                            <form onSubmit={LoginBtn} method="post">
                                <div className="input-group mb-3">
                                    <input type="text" name='username' onChange={(e) => setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue?.username} className="form-control" placeholder="Username" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" name="password" onChange={(e) => setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue?.password} className="form-control" placeholder="Password" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            {/* <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        Remember Me
                                    </label> */}
                                        </div>
                                    </div>
                                    {/* /.col */}
                                    <div className="col-4">
                                        <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                    </div>
                                    {/* /.col */}
                                </div>
                            </form>
                        </div>
                </>
            )
        } else {
            return (    
                <>
                    <div className="login-logo">
                        {/* <img src={require('./384540628_1008238667298426_6685715244998088129_n.jpg')} style={{width:200, height:200, borderRadius:100}} /> */}
                        {/* <div style={{ lineHeight: 1 }}><a>Aguilar Medical Clinic <br /> & <br /> Pharmacy</a></div> */}
                        <div><a>John Doe, MD</a></div>
                        {/* <div><a style={{ fontSize: 18 }}>MD, FPPS, GENERAL PEDIATRICS</a></div> */}
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
                            <form onSubmit={LoginBtn} method="post">
                                <div className="input-group mb-3">
                                    <input type="text" name='username' onChange={(e) => setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue?.username} className="form-control" placeholder="Username" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" name='password' onChange={(e) => setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue?.password} className="form-control" placeholder="Password" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            {/* <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        Remember Me
                                    </label> */}
                                        </div>
                                    </div>
                                    {/* /.col */}
                                    <div className="col-4">
                                        <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                    </div>
                                    {/* /.col */}
                                </div>
                            </form>
                        </div>
                        {/* /.login-card-body */}
                    </div>
                </>
            )
        }
    }




    return (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100vh"}}>
            {/* <video src={require('./356955600_6510487162366595_2239991482821432230_n.mp4')} style={{ width:"100%", height:"100%", objectFit:"cover"}} autoPlay loop muted /> */}
            <div className="login-box" style={{opacity:0.89, position:"absolute"}}>
                {
                    Display()
                }
            </div>
        </div>

    );
}

export default Login;
